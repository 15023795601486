<template>
  <div>
    <v-sheet
      v-if="!categorie.length"
      :color="`grey`"
      class="pa-3"
    >
      <v-skeleton-loader
        class="mx-auto"
        type="text-field, table, data-table"
      >
      </v-skeleton-loader>
    </v-sheet>
    <v-card v-else>
      <snack-view
        :message="snackbar.message"
        :activate="snackbar.active"
        :bg-color="snackbar.color"
      ></snack-view>
      <div>
        <v-card-title class="text-uppercase font-weight-bold">
          Liste des permissions
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              offset-md="8"
              md="4"
            >
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                label="Rechercher"
                single-line
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- Table -->
        <v-data-table
          :headers="headers"
          :items="categorie"
          :search="search"
          :options="dataOptions"
          :items-per-page="150"
          sort-by="id"
          class="table-kitchen-sink"
        >
          <!-- product -->
          <template #[`item.title`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.title }}</span>
              </div>
            </div>
          </template>

          <template #[`item.id`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ categorie.indexOf(item) + 1 }}</span>
              </div>
            </div>
          </template>

          <template #[`item.departement`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ nomCategorie(item.permission_categorie_id) }}</span>
              </div>
            </div>
          </template>

          <template #[`item.created_at`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ moment(item.created_at).format('D MMMM YYYY') }}</span>
              </div>
            </div>
          </template>

          <template #[`item.updated_at`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ moment(item.updated_at).format('D MMMM YYYY') }}</span>
              </div>
            </div>
          </template>

          <template #[`item.std`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{nomType(item.std)}}</span>
              </div>
            </div>
          </template>

          <template #[`item.state`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ">
                <v-chip
                  v-if="item.state==1"
                  class="ma-2 text-center pr-5 pl-5"
                  color="#F1FFE8FF"
                >
                  <b class="success--text">actif</b>
                </v-chip>
                <v-chip
                  v-else
                  class="ma-2 text-center pr-5 pl-5"
                  color="#ffcfcf"
                >
                  <b class="error--text">bloqué</b>
                </v-chip>
              </div>
            </div>
          </template>
          <!-- action -->
          <template #[`item.actions`]="{ item }">
            <div class="col-12 w-">
              <v-btn
                outlined
                x-small
                color="info"
                @click="editItem(item)"
              >
                <v-icon
                  x-small
                  class="me-2"
                >
                  {{ icons.mdiPencilOutline }}
                </v-icon>
                Modifier
              </v-btn>
            </div>
          </template>
        </v-data-table>

        <!-- dialog édition -->
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span>Modifier une permission</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-form
                    ref="updatePermissionForm"
                  >
                    <v-col
                      cols="12"
                      class="mb-n6"
                    >
                      <v-select
                        v-model="editedItem.permission_categorie_id"
                        outlined
                        dense
                        :items="permissionCategorie"
                        :rules="LocalNameRules"
                        item-text="name"
                        item-value="id"
                        label="Catégorie de permission"
                      ></v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      class="mb-n6"
                    >
                      <v-select
                        v-model="editedItem.std"
                        outlined
                        dense
                        :items="typePermission"
                        :rules="LocalNameRules"
                        item-text="name"
                        item-value="id"
                        label="Type de permission"
                      ></v-select>
                    </v-col>

                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.title"
                        label="Titre"
                        :rules="LocalNameRules"
                        outlined
                        dense
                        placeholder="titre"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>

                    <!--                    <v-col-->
                    <!--                      cols="12"-->
                    <!--                      offset-md="2"-->
                    <!--                      md="8"-->
                    <!--                  >-->
                    <!--                    <v-text-field-->
                    <!--                      v-model="editedItem.name"-->
                    <!--                        label="Nom"-->
                    <!--                        :rules="LocalNameRules"-->
                    <!--                      outlined-->
                    <!--                      dense-->
                    <!--                        placeholder="Nom"-->
                    <!--                        hide-details="auto"-->
                    <!--                    ></v-text-field>-->
                    <!--                    </v-col>-->

                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.route"
                        label="Route"
                        outlined
                        dense
                        placeholder="Route"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pr-0 mt-n8 pb-10"
                    >
                      <div
                        class="d-flex flex-row align-items-end"
                      >
                        <h3 class="mt-5 mr-2 font-weight-light">
                          <span>Etat </span>
                        </h3>
                        <v-switch
                          v-model="editedItem.status"
                          inset
                          color="success"
                        >
                          <template v-slot:label>
                            <span :class="editedItem.status?'success--text':'error--text'">
                              {{ editedItem.status?'actif':'bloqué' }}
                            </span>
                          </template>
                        </v-switch>
                      </div>
                    </v-col>
                  </v-form>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="close"
              >
                Annuler
              </v-btn>
              <v-btn
                color="primary"
                @click="save"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- dialog suppression -->
        <v-dialog
          v-model="dialogDelete"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="success"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiEyeOutline,
} from '@mdi/js'
import data from '@/views/locaux/datatable'
import Http from '@/helpers/http'
import Vue from 'vue'
import moment from 'moment'
// eslint-disable-next-line import/extensions
import SnackView from '@/views/SnackView'

import { get_type_char, POSTPAYE_TYPE, PREPAYE_TYPE } from '@/helpers/constants/contrat'

Vue.prototype.moment = moment

export default {
  components: {
    SnackView,
  },
  setup() {
    const search = ref('')
    const categorie = ref([])
    const permissionCategorie = ref([])
    const snackbar = ref({
      active: false,
      message: '',
      color: 'success',
    })

    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]

    const getCategorie = () => {
      Http.get('permission-categorie')
        .then(async response => {
          // eslint-disable-next-line no-multi-assign
          permissionCategorie.value = await response.data
        })
    }

    const nomCategorie = id => {
      // eslint-disable-next-line array-callback-return,no-return-assign
      let name = permissionCategorie.value.filter(item => name = item.id === id)
      if (name.length === 1) return name[0].name

      return id
    }

    const nomType = id => {
      return get_type_char(id)
    }

    const loadPermisionCategorie = () => {
      Http.get('permission-categorie')
        .then(async response => {
          permissionCategorie.value = await response.data
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    const loadPermissions = () => {
      Http.get('permission')
        .then(async response => {
          categorie.value = await response.data
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    // onBeforeMount(() => {
    //   loadPermisionCategorie()
    // })

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiEyeOutline,
      },
      search,
      LocalNameRules,
      getCategorie,
      nomCategorie,
      loadPermisionCategorie,
      permissionCategorie,
      nomType,
      headers: [
        { text: 'N°', value: 'id', divider: true },
        { text: 'Titre', value: 'title', divider: true },
        { text: 'Catégorie', value: 'departement', divider: true },
        { text: 'Route', value: 'route', divider: true },
        { text: 'Date de création', value: 'created_at', divider: true },
        { text: 'Dernière MAJ', value: 'updated_at', divider: true },
        { text: 'type', value: 'std', divider: true },
        { text: 'Etat', value: 'state', divider: true },
        {
          text: 'Actions', value: 'actions', sortable: false, divider: true,
        },
      ],
      typePermission : [
        { id: PREPAYE_TYPE, name: get_type_char(PREPAYE_TYPE) },
        { id: POSTPAYE_TYPE, name: get_type_char(POSTPAYE_TYPE) },
        { id: 3, name: get_type_char(3) },
      ],
      dataOptions: {
        sortBy: ['id'],
      },
      categorie,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      dialog: false,
      dialogDelete: false,
      snackbar,
      loadPermissions,
    }
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close()
    },
    dialogDelete(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeDelete()
    },
  },
  mounted() {
    moment.locale('fr')
  },
  created() {
    this.loadPermisionCategorie()
    this.loadPermissions()
  },
  methods: {
    initialize() {
      this.userList = JSON.parse(JSON.stringify(data))
    },

    editItem(item) {
      console.log(item)
      this.editedIndex = this.categorie.indexOf(item)
      // eslint-disable-next-line no-param-reassign
      console.log('item.state')
      console.log(item.state)
      item.status = item.state === 1
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.categorie.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.categorie.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      console.log(this.editedItem)
      if (this.$refs.updatePermissionForm.validate()) {
        this.snackbar.active = false
        this.editedItem.state = this.editedItem.status ? 1 : -1
        this.editedItem.status = undefined
        Http.put(`/permission/${this.editedItem.id}`, {
          state: this.editedItem.state,
          permission_categorie_id: this.editedItem.permission_categorie_id,
          title: this.editedItem.title,
          route: this.editedItem.route,
          std: this.editedItem.std,
        })
          .then(response => {
            if (response.status === 201) {
              this.loadPermissions()
              this.snackbar.message = response.message
              this.snackbar.active = true
            }
          })
          .catch(error => {
            console.log(error)
          })

        this.close()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
